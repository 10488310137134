import React from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import CloseIcon from '@talentinc/gatsby-theme-ecom/images/close.inline.svg'

const CloseButton = withStyles(
  (props: IconButtonProps) => (
    <IconButton size="large" {...props}>
      <CloseIcon />
    </IconButton>
  ),
  (theme) => ({
    colorPrimary: {
      '& g[stroke]': {
        stroke: `${theme.palette.primary.main} !important`,
      },
    },
    colorSecondary: {
      '& g[stroke]': {
        stroke: `${theme.palette.secondary.main} !important`,
      },
    },
  })
)

export default CloseButton
