import { PageProps, graphql } from 'gatsby'
import React from 'react'

import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

import CallToActionDrawer from '@talentinc/gatsby-theme-ecom/components/CallToAction/CallToActionDrawer'
import { ScrollProgressProvider } from '@talentinc/gatsby-theme-ecom/components/Post/ScrollProgress'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import BasePostTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/Post'
import { CTA } from '@talentinc/gatsby-theme-ecom/types/cta'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'
import { Post } from '@talentinc/gatsby-theme-ecom/types/post'

import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'

type Props = PageProps<
  PageDataContextType & {
    post: Post
    defaultPostPopup: CTA
  },
  PageContextType
>

const PostTemplate: React.FC<Props> = (props) => {
  const { post, defaultPostPopup, ...pageData } = props.data
  let scrollPercentageThreshold = 30

  if (post.fields.readingTime.minutes > 20) {
    scrollPercentageThreshold = 5
  } else if (post.fields.readingTime.minutes > 10) {
    scrollPercentageThreshold = 15
  }

  return (
    <Layout pageTitle={post.title} pageData={pageData}>
      {post.seo ? (
        <SEO {...post.seo} post={post} noIndex={props.pageContext.noIndex} />
      ) : (
        <SEO noIndex={props.pageContext.noIndex} />
      )}
      <ScrollProgressProvider>
        <HeaderV2 showBlogNavigation />
        <BasePostTemplate post={post} />
        <CallToActionDrawer
          cta={defaultPostPopup}
          scrollPercentageThreshold={scrollPercentageThreshold}
        />
      </ScrollProgressProvider>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query PostsBySlugAndBrand($slug: String!, $brandName: String!) {
    post: contentfulPost(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...Post
    }
    defaultPostPopup: contentfulCallToAction(
      contentful_id: { eq: "4V7wBfsm0IXsNWQA102hOz" }
    ) {
      ...CallToAction
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default PostTemplate
