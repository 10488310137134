import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Drawer, Theme, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import { differenceInDays, parseISO } from 'date-fns'
import omit from 'lodash/omit'

import CallToAction, {
  CallToActionProps,
} from '@talentinc/gatsby-theme-ecom/components/CallToAction'
import { useScrollProgress } from '@talentinc/gatsby-theme-ecom/components/Post/ScrollProgress'
import { CTA, CTAVariants } from '@talentinc/gatsby-theme-ecom/types/cta'
import { ButtonRole } from '@talentinc/gatsby-theme-ecom/types/page'
import useEvents, {
  useEventSubscription,
  SendEventPayload,
} from '@talentinc/gatsby-theme-ecom/hooks/useEvents'
import CloseButton from '@talentinc/gatsby-theme-ecom/components/Buttons/CloseButton'

interface Props {
  cta: CTA
  scrollPercentageThreshold?: number
}

const CallToActionDrawer: React.FC<Props> = ({
  cta,
  scrollPercentageThreshold,
}) => {
  const { shown, dismiss } = usePostPopup(cta, scrollPercentageThreshold)
  const { classes } = useStyles()
  const showCloseButton = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  )

  const updatedCTA = useMemo<CallToActionProps['cta']>(
    () => ({
      ...cta,
      variant: CTAVariants.Drawer,
      classes: omit(classes, ['closeButton']),
      buttons: cta.buttons.map((btn) => {
        if (btn.role === ButtonRole.Dismiss) {
          return {
            ...btn,
            onClick: dismiss,
          }
        }

        return btn
      }),
    }),
    [dismiss, cta, classes]
  )

  return (
    <Drawer
      open={shown}
      anchor="bottom"
      variant="persistent"
      ModalProps={{
        hideBackdrop: true,
      }}
    >
      {showCloseButton && (
        <CloseButton
          aria-label="close the pop up"
          onClick={dismiss}
          className={classes.closeButton}
        />
      )}
      <CallToAction cta={updatedCTA} />
    </Drawer>
  )
}

function usePostPopup(cta: CTA, scrollPercentageThreshold = 30) {
  const [shown, showDrawer] = useState(false)
  const { scrollProgress } = useScrollProgress()
  const sendEvent = useEvents()
  const [popUpHistory, setPopUpHistory] = useLocalStorage<Record<string, Date>>(
    'popUpHistory',
    {},
    {
      raw: false,
      serializer: JSON.stringify,
      deserializer: (value) =>
        JSON.parse(value, (_, v) => {
          if (typeof v === 'string') {
            return parseISO(v)
          }

          return v
        }),
    }
  )
  const userHasInteractedWithPopup =
    !!popUpHistory?.[cta.contentful_id] &&
    differenceInDays(popUpHistory[cta.contentful_id], new Date()) < 180

  const dismiss = useCallback(() => {
    showDrawer(false)
    setPopUpHistory((hist) => ({
      ...hist,
      [cta.contentful_id]: new Date(),
    }))
    sendEvent({
      event: 'Contentful Post Popup Dismissed',
      variables: {
        contentful: {
          callToActionID: cta.contentful_id,
        },
      },
    })
  }, [cta.contentful_id, sendEvent, setPopUpHistory])

  const handleExternalEvents = useCallback(
    (event: SendEventPayload) => {
      if (event.variables.contentful?.modalVariant === 'Resume Upload') {
        dismiss()
      }
    },
    [dismiss]
  )

  useEffect(
    () =>
      showDrawer((alreadyShown) => {
        if (userHasInteractedWithPopup) {
          return false
        }

        if (alreadyShown) {
          return true
        }

        if (scrollProgress > scrollPercentageThreshold) {
          sendEvent({
            event: 'Contentful Post Popup Shown',
            variables: {
              contentful: {
                callToActionID: cta.contentful_id,
              },
            },
          })

          return true
        }

        return false
      }),
    [
      scrollProgress,
      cta.contentful_id,
      userHasInteractedWithPopup,
      sendEvent,
      scrollPercentageThreshold,
    ]
  )

  useEventSubscription('Contentful Modal Completion', handleExternalEvents)

  return { shown, dismiss }
}

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.colors?.cyan?.[800] ?? theme.palette.primary.dark,

    [theme.breakpoints.up('xs')]: {
      textAlign: 'left',
      paddingTop: '1.875em',
      paddingBottom: '1.875em',
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 0,
      paddingTop: '1em',
    },
    '@media (min-width: 1456px)': {
      paddingLeft: 'calc((100% - 91rem)/2 + 5.5rem)',
      paddingRight: 'calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
  header: {
    maxWidth: '100%',

    '&& > *': {
      fontFamily: theme.typography.h1.fontFamily,
      fontSize: theme.typography.h1.fontSize,
      fontWeight: 'bold',
      letterSpacing: '0.4px',
      lineHeight: '1.278',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.375em',
        fontWeight: 'bold',
        letterSpacing: '-0.2px',
      },
    },
  },
  text: {
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  actions: {
    '&&': {
      paddingLeft: 0,
      paddingTop: '.9em',

      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,

        '& > *': {
          width: '100%',
        },
        '& > *:not(:first-of-type)': {
          display: 'none',
        },
      },
    },
  },
  closeButton: {
    position: 'absolute',
    zIndex: 30000,
    right: '11px',
    top: '-2px',
  },
}))

export default CallToActionDrawer
